import styled from "styled-components"
import Box from "src/components/UI/Layout/Box"

const ImageWrapper = styled(Box)`
  width: 100%;
  min-height: 420px;
  position: relative;
  overflow: hidden;

  @media (min-width: 374px) {
    min-height: 460px;
  }

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    min-height: 90vh;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
`

ImageWrapper.defaultProps = {}

export default ImageWrapper
